import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout>
    <div style={{ 'text-align': 'center' }}>
      <h1>404: Page Not Found</h1>
      <StaticImage src="../img/404.jpg" alt="Page Not Found" />
    </div>
  </Layout>
);

export default NotFoundPage;
